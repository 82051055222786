<template>
  <div class="registerPage">
    <div class="head" @click="$router.go(-1)">
      <svg-icon iconClass="leftArrow" class="backBtn"></svg-icon>
    </div>
    <div class="title">手机号注册</div>
    <div class="typeBox">
      <div class="registerType leftBox" :class="{active: registerType == 'phone'}" @click="chageType('phone')">
        <svg-icon iconClass="phone" class="typeIcon" />
        <div>手机号码</div>
      </div>
      <div class="registerType rightBox" :class="{active: registerType == 'email'}" @click="chageType('email')">
        <svg-icon iconClass="email" class="typeIcon" />
        <div>邮箱</div>
      </div>
    </div>
    <!-- 手机号输入框 -->
    <div class="inputBox" v-if="registerType == 'phone'">
      <div class="areaCodeInp">
        <van-field class="areaCode" v-model="areaCode" label="+" placeholder="区号" />
      </div>
      <div class="phoneInp">
        <van-field placeholder="请输入手机号" type="tel" v-model="phoneValue" />
      </div>
    </div>
    <!-- 邮箱输入框 -->
    <div class="emailBox">
      <div class="inputBox" v-if="registerType == 'email'">
        <input type="text" class="emailInput" placeholder="请输入您的邮箱账号" v-model="emailValue" @blur="onBlur" @input="onFocus">
      </div>
      <!-- 邮箱自动补全 -->
      <transition name="fade">
        <div class="autoMail-box" v-show="emailArr.length > 0 && isBlur && emailValue">
          <ul>
            <li v-for="(mail, index) in emailArr" @mouseover="current = index" :class="{current: index == current}" :key="mail" @click="chooseMail">{{prefix(emailValue) + mail}}</li>
          </ul>
        </div>
      </transition>
      <!-- 邮箱自动补全 -->
    </div>

    <!-- 密码 -->
    <div class="inputBox mt24">
      <svg-icon iconClass="lock" class="lock"></svg-icon>
      <van-field placeholder="请输入密码" type="password" v-model="pwdValue" />
    </div>
    <!-- 确认密码 -->
    <div class="inputBox mt24">
      <svg-icon iconClass="lock" class="lock"></svg-icon>
      <van-field placeholder="请再次输入密码" type="password" v-model="confirmPwd" />
    </div>
    <div class="nextBtn" @click="sendCode">下一步</div>
    <router-link tag="div" to="login" class="toLogin">
      <div>已有账号立即登录</div>
      <svg-icon iconClass="rightArrow"></svg-icon>
    </router-link>
  </div>
</template>

<script>
import { captcha } from "@/api/user.js";
export default {
  data() {
    return {
      /**  邮箱自动补全*/
      value: "",
      isBlur: false,
      current: 0, // 当前选中的mail类型
      // 邮箱提示、补全
      emailArr: [
        "qq.com",
        "163.com",
        "126.com",
        "yeah.net",
        "sina.com",
        "aliyun.com",
        "outlook.com",
        "hotmail.com",
        "foxmail.com",
        "yahoo.com",
        "139.com",
        "sohu.com",
        "gmail.com",
      ],
      /**  邮箱自动补全*/
      areaCode: "86", // 区号
      phoneValue: "", // 手机号
      emailValue: "",
      pwdValue: "", // 密码
      confirmPwd: "", // 确认密码
      registerType: "phone", // 注册类型
    };
  },
  methods: {
    //邮箱自动补全
    prefix(value) {
      // 获取前缀
      let inx = value.indexOf("@");
      if (inx <= 0) return `${value}@`;
      let prefix = value.substr(0, inx);
      return `${prefix}@`;
    },
    onBlur() {
      setTimeout(() => {
        this.isBlur = false;
      }, 200);
    },
    onFocus() {
      this.isBlur = true;
    },
    chooseMail(event) {
      // 选择邮箱li
      let value = event.target.innerHTML;
      this.emailValue = value;
      this.isBlur = false;
    },
    //邮箱自动补全

    chageType(type) {
      this.registerType = type;
      this.phoneValue = "";
      this.emailValue = "";
      this.pwdValue = "";
      this.confirmPwd = "";
      this.areaCode = "86";
    },
    async sendCode() {
      let emailReg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,5})$/;

      if (!this.areaCode && this.registerType == "phone") {
        this.$toast("请输入区号");
        return;
      }
      if (!this.phoneValue && this.registerType == "phone") {
        this.$toast("请输入手机号");
        return;
      }
      if (!emailReg.test(this.emailValue) && this.registerType == "email") {
        this.$toast("请输入正确的邮箱");
        return;
      }
      if (!this.pwdValue) {
        this.$toast("请输入密码");
        return;
      }
      if (this.pwdValue.length < 6) {
        this.$toast("密码长度至少6位");
        return;
      }
      if (this.pwdValue != this.confirmPwd) {
        this.$toast("两次密码不一致");
        return;
      }
      let req = {
        mobile:
          this.registerType == "phone"
            ? "+" + this.areaCode + this.phoneValue
            : undefined,
        email: this.registerType == "email" ? this.emailValue : undefined,
      };
      let registerInfo = {
        areaCode: this.areaCode,
        phoneNo: this.phoneValue,
        email: this.emailValue,
        pwd: this.pwdValue,
        registerType: this.registerType,
      };
      this.$toast.loading({
        duration: 0,
      });
      let ret = await this.$Api(captcha, req);
      this.$toast.clear();
      if (ret && ret.code == 200) {
        sessionStorage.setItem("registerInfo", JSON.stringify(registerInfo));
        this.$router.push("sendCode");
      } else {
        this.$toast(ret.tip || "验证码发送失败");
        // sessionStorage.setItem("registerInfo", JSON.stringify(registerInfo));
        // this.$router.push("sendCode");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.registerPage {
  background: #f6f6f6;

  .head {
    width: 48px;
    height: 44px;
    display: flex;
    align-items: center;

    .backBtn {
      width: 16px;
      height: 16px;
      margin: 0 16px;
    }
  }

  .title {
    margin: 12px 0 34px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .typeBox {
    width: 280px;
    height: 40px;
    display: flex;
    margin: 0 auto 27px;
    font-size: 14px;
    background: $white1;

    .registerType {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 40px;
      color: #707070;
      box-sizing: border-box;

      .typeIcon {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }

    .leftBox {
      border: 1px solid #707070;
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }

    .rightBox {
      border: 1px solid #707070;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }

    .active {
      border: 1px solid #2c55ef;
      background: rgba(113, 141, 255, 0.4);
      color: #2c55ef;
    }
  }

  .inputBox {
    overflow: hidden;
    width: 278px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid $greyishBrown;
    margin: 0 auto;
    background: $white1;
    display: flex;
    align-items: center;

    .lock {
      width: 20px;
      height: 20px;
      margin-left: 20px;
    }

    .emailInput {
      width: 100%;
      margin: 10px 22px;
      height: 24px;
      font-size: 14px;
    }

    .emailInput::-webkit-input-placeholder {
      color: #cacbce;
      font-size: 14px;
    }

    .emailInput::-ms-input-placeholder {
      color: #cacbce;
      font-size: 14px;
    }

    .emailInput::-moz-placeholder {
      color: #cacbce;
      font-size: 14px;
    }

    .emailInput::-moz-placeholder {
      color: #cacbce;
      font-size: 14px;
    }

    .areaCodeInp {
      width: 80px;
      padding: 0 3px;
      box-sizing: border-box;
      border-right: 1px solid $greyishBrown;
      display: flex;
      justify-content: center;
      align-items: center;

      .areaCode {
        /deep/ .van-field__label {
          width: 8.7px;
          margin: 0;
        }
      }
    }

    .phoneInp {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 198px;

      input {
        width: 70%;
      }
    }
  }

  .nextBtn {
    width: 278px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 27px;
    margin: 0 auto;
    text-align: center;
    color: $white1;
    background: $vermillion;
    margin-top: 28px;
  }

  .toLogin {
    margin-top: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    div {
      margin-right: 9px;
    }
  }

  .mt24 {
    margin-top: 24px;
  }
}

.emailBox {
  position: relative;
}
.autoMail-box {
  transform: translate(-50%, 0);
  top: 50px;
  left: 50%;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 0;
  z-index: 999;
  padding: 10px 5px 10px 10px;
  ul {
    margin: 10px;
    max-height: 280px;
    max-width: 250px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.autoMail-box::-webkit-scrollbar-thumb:window-inactive {
  background: #999;
}
.autoMail-box ul {
  list-style: none;
  min-width: 200px;
  margin: 0;
  padding: 0;
}
.autoMail-box ul li {
  color: #333;
  margin: 0;
  padding: 0;
  line-height: 30px;
  padding: 0 10px;
}
.autoMail-box ul li.current {
  background-color: lightblue;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>